export default {
  async selectParteTrabajo (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.tactuacion_tsistema,
        tables.orden_trabajo.idtactuacion_tsistema.eq(tables.tactuacion_tsistema.idtactuacion_tsistema)
      )
      .innerJoin(
        tables.tactuacion,
        tables.tactuacion_tsistema.idtactuacion.eq(tables.tactuacion.idtactuacion)
      )
      .leftOuterJoin(
        tables.checklist_ot,
        tables.orden_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          Vue.$offline.db.op.or(
            tables.checklist_ot.estado.gt(0),
            tables.checklist_ot.idchecklist_ot.isNull(),
          )
        )
      )
      .exec()
    )[0]
  },
  async selectCantAccionesPendientes (Vue, idparteTrabajo) {
    return (await Vue.$offline.parteTrabajo.selectCantAccionesPendientes(idparteTrabajo))[0].cant_acciones_pendientes
  },
  async selectPreguntasNoContestadas (Vue, idchecklistOt) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(
        Vue.$offline.db.fn.count(tables.pregunta_checklist_ot.idpregunta_checklist_ot).as('cantidad')
      )
      .from(tables.pregunta_checklist_ot)
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .leftOuterJoin(
        tables.orden_trabajo_matsist,
        tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .leftOuterJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.pregunta_checklist_ot.idgrado_anomalia.isNull(),
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.material_sistema.fdesinstalacion.isNull(),
        )
      )
      .exec()
    )[0].cantidad
  },
  async selectTiempoTrabajado (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(
        Vue.$offline.db.fn.count(tables.tiempo_trabajado.idtiempo_trabajado).as('cantidad')
      )
      .from(tables.tiempo_trabajado)
      .where(tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo))
      .exec()
    )[0].cantidad
  },
  async selectCantMaterialAfectado (Vue, idordenTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(
        Vue.$offline.db.fn.count(tables.orden_trabajo_matsist.idorden_trabajo_matsist).as('cantidad')
      )
      .from(tables.orden_trabajo_matsist)
      .where(Vue.$offline.db.op.and(
        tables.orden_trabajo_matsist.idorden_trabajo.eq(idordenTrabajo),
        tables.orden_trabajo_matsist.estado.gt(0)
      ))
      .exec()
    )[0].cantidad
  },
}
